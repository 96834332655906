.tree-container {
    //z-index: 2;
    //size: 0px;
    
    //margin-top: 100px;
    //height: 800px;
    //bottom: 0;
    //width:100%
    //left: auto;
    //margin: auto;
    opacity: 1;


    img {
        position: absolute;
        //height:805px;
        //width:745px;
        right: 0;
        bottom: 0;
        padding: 0;
        margin: 0;
        display:block;
        opacity:1;
    }
    
}
