
.bst-zone {
    .bst-code {
        //display:inline-block;
        //height: 7000px;
        //width: 50%;
        //float: left;
        //color: #ffffff;
        
    }
    .bst-research {
        //display: inline-block;
        //height: 7000px;
        width: 50%;
        margin: auto;
        //float: right;
        font-family: "JetBrains";
        font-size: 20px;
    }

}
