.bottombar {
    background: #0e141a;
    bottom:0;
    //margin:0;
    width: 100%;
    height: 250px;
    list-style-type: none;
    //animation: bounceIn 1s 1s;
    //display: inline;
    //text-align: center;
    //padding-top: 200px;
    //position: static;

    overflow: hidden;
    
    nav {

        display: block;
        font-size: 70px;
        text-align: center;
        
        color: #ffd700;
        margin-bottom: 0px;
        padding-top: 40px;
        a{ 
            color: #ffffff;
            
            &:hover svg {
                color: #ffd700;
                //color: #c91111;
             
            }
            a.page-link {
                padding-left: 30px;
                &:after {
                    content: 'SCRIPTS';
                }
    
            }
        }
        

        // a.active {
        //     svg {
        //         color: #ffd700;
        //     }
        // }
        //padding: 0px;

    }


    .outside-link {
        ul {
            text-align: center;
            font-size: 70px;
            li {
                display: inline;
                padding-right: 30px;
            }
            
            margin:0;
            //float:left;
            
        }
    }
  //bottom: 0;
  //width: 100%;
}